import React from 'react'
// import ReactDOM from 'react-dom'
import { graphql } from 'gatsby'
// import './index.css'
// import App from './App'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Matches from '../components/Matches'
// import Matches from '../components/MatchesEuro'
import HeadTags from '../components/Headtags'
import GoogleAd from '../components/GoogleAd'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
import {Container,Row,Col} from "react-bootstrap"

// import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from '../aws-exports';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(config);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
// IS THIS WORKING ?????

const IndexPage = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
    <Row>
        <SnackAd2 ad_type = "leader" ad_num = {1}/>
    </Row>
      <Header />
      <Matches />
      <Container fluid>
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)
      // <Footer />
      // <h1> {data.site.siteMetadata.title} </h1>

// export const query = graphql `query IndexPageQuery {
//     site {
//       siteMetadata {
//         title
//     }
//   }
// }
// `


export default IndexPage
